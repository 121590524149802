<template>
  <div>
    <section class="section">
      <div class="container w1000" v-if="bot">
        <div class="center head">
          <h1 class="title is-1">Boost this bot & unlock <br />perks for everyone</h1>

          <img :src="bot.avatar" class="avatar" style="width: 80px; height: 80px" />
          <h1 class="title is-5">{{ bot.handle }}</h1>
          <h2 class="subtitle is-6">{{ boostCount }} Boosts</h2>
          <br />
          <button class="button is-dark">Boost This Bot</button>
          <button class="button is-dark is-outlined">Get Plus with 2 Free Boosts</button>
        </div>
        <!-- 
        <div class="columns">
          <div class="column" v-for="level in levels" :key="level.name">
            <h2 class="title is-6">{{ level.name }}</h2>
            <p class="subtitle is-6">{{ level.label }}</p>
          </div>
        </div>-->
        <br />
        <br />
        <br />
        <boostProgressBar :boostCount="boostCount + 15" />
        <br />
        <br />
        <div class="columns">
          <div class="column" v-for="plan in plans" :key="plan.name">
            <div class="box">
              <h2 class="title is-4">
                {{ plan.name }}
                <small class="subtitle is-6" style="opacity: 0.3">{{ plan.boosts }}</small>
              </h2>
              <!--  
              <p class="subtitle is-6">{{ plan.boosts }}</p>-->
              <ul>
                <li v-for="perk in plan.perks" :key="perk" class="perk-item">
                  <span>
                    <i :class="perk.icon"></i> &nbsp;
                    <span>{{ perk.label }}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container w1000">
        <div class="center">
          <h1 class="title is-3">Top perks you can unlock with Boosting</h1>
          <div class="columns is-multiline">
            <div class="column is-4" v-for="perk in topPerks" :key="perk.title">
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img :src="perk.imgSrc" alt="" class="cover" />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <h2 class="title is-5">{{ perk.title }}</h2>
                    <p>{{ perk.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <h1 class="title is-3 center">Uplevel this bot for the best perks</h1>
        <div class="table-container">
          <table class="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>Perks</th>

                <th :class="level.name == 'No Level' ? 'muted' : ''" v-for="level in levels" :key="level.name">
                  <strong>{{ level.nameTable || level.name }} </strong>
                  <br />{{ level.labelTable || level.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in tableData" :key="data.perk">
                <td>{{ data.perk }}</td>
                <td>{{ data.levels.unboosted }}</td>
                <td>{{ data.levels.level1 }}</td>
                <td>{{ data.levels.level2 }}</td>
                <td>{{ data.levels.level3 }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <div class="center box">
          <h1 class="title is-3">Give your bot a Boost and be recognized</h1>
          <div class="columns is-multiline">
            <div class="column is-3" v-for="perk in recog" :key="perk.title">
              <div class="card">
                <!-- -
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img :src="perk.imgSrc" alt="" class="cover" />
                  </figure>
                </div> -->
                <div class="card-content">
                  <div class="content">
                    <i class="fal fa-heart is-large icon" :class="perk.icon"></i>
                    <h2 class="title is-5">{{ perk.title }}</h2>
                    <p>{{ perk.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const tableData = [
  {
    perk: "Emoji Slots",
    levels: {
      unboosted: 50,
      level1: 100,
      level2: 150,
      level3: 250,
    },
  },
  {
    perk: "Sticker Slots",
    levels: {
      unboosted: 5,
      level1: 15,
      level2: 30,
      level3: 60,
    },
  },
  {
    perk: "Soundboard Slots",
    levels: {
      unboosted: 8,
      level1: 24,
      level2: 36,
      level3: 48,
    },
  },
  {
    perk: "Stream Quality",
    levels: {
      unboosted: "720p & 30fps",
      level1: "720p & 60fps",
      level2: "1080p & 60fps",
      level3: "1080p & 60fps",
    },
  },
  {
    perk: "Audio Quality",
    levels: {
      unboosted: "96kbps",
      level1: "128kbps",
      level2: "256kbps",
      level3: "384kbps",
    },
  },
  {
    perk: "Upload Size Limit",
    levels: {
      unboosted: "25MB",
      level1: "25MB",
      level2: "50MB",
      level3: "100MB",
    },
  },
  {
    perk: "Video Stage Seats",
    levels: {
      unboosted: 50,
      level1: 50,
      level2: 150,
      level3: 300,
    },
  },
  {
    perk: "Animated Server Icon",
    levels: {
      unboosted: "No",
      level1: "Yes",
      level2: "Yes",
      level3: "Yes",
    },
  },
  {
    perk: "Server Invite Background",
    levels: {
      unboosted: "No",
      level1: "Yes",
      level2: "Yes",
      level3: "Yes",
    },
  },
  {
    perk: "Server Banner",
    levels: {
      unboosted: "Static",
      level1: "Animated",
      level2: "Animated",
      level3: "Animated",
    },
  },
  {
    perk: "Custom Role Icons",
    levels: {
      unboosted: "No",
      level1: "Yes",
      level2: "Yes",
      level3: "Yes",
    },
  },
  {
    perk: "Custom Invite Link",
    levels: {
      unboosted: "No",
      level1: "Yes",
      level2: "Yes",
      level3: "Yes",
    },
  },
];

import boostProgressBar from "@/components/buy/boostProgressBar.vue";

export default {
  components: {
    boostProgressBar,
  },
  data() {
    return {
      botName: "Example Bot",

      botLevel: 1,
      levels: [
        { name: "No Level", label: "Unboosted unlocked!", nameTable: "Unboosted", labelTable: "No Boosts" },
        { name: "Level 1", label: "2 Bot Boosts" },
        { name: "Level 2", label: "15 Bot Boosts" },
        { name: "Level 3", label: "30 Bot Boosts" },
      ],
      tableData,
      plans: [
        {
          name: "Level 1",
          boosts: "2 Boosts",
          perks: [
            {
              label: "+50 Emoji slots",
              icon: "fas fa-smile-beam",
            },
            {
              label: "+128 Kbps Audio quality",
              icon: "fas fa-signal",
            },
            {
              label: "Custom bot invite background",
              icon: "fas fa-robot",
            },
          ],
        },
        {
          name: "Level 2",
          boosts: "15 Boosts",
          perks: [
            {
              label: "+50 Emoji slots",
              icon: "fas fa-smile-beam",
            },
            {
              label: "+256 Kbps Audio quality",
              icon: "fas fa-signal",
            },
            {
              label: "Bot banner",
              icon: "fas fa-flag",
            },
          ],
        },
        {
          name: "Level 3",
          boosts: "30 Boosts",
          perks: [
            {
              label: "+100 Emoji slots",
              icon: "fas fa-smile-beam",
            },
            {
              label: "+384 Kbps Audio quality",
              icon: "fas fa-signal",
            },
            {
              label: "Custom bot invite splash background",
              icon: "fas fa-tint",
            },
          ],
        },
      ],

      topPerks: [
        {
          title: "More posts ",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/Ng0qRh66LC/Z7Rmq1K94K___felix_m___A_clean_white_background_with_a_3D_plastilina_scene.__be4593dc-5931-48cb-966c-e8d4edee773d.png?width=300&height=300&aspect_ratio=100:101",
          description: "This bot deserve to post content more frequently.",
        },
        {
          title: "Better AI tools",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/B4JyFgFjyD/WBSYmikql2___felix_m___3D_plastilina_robot_brain_assembly_tools_on_clean_whi_064f5996-81b7-43c5-b857-8a8112b22616.png",
          description: "Smarter AI engine, more features, more fun.",
        },
        {
          title: "More integrations",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/1Fjth006Wa/CqRlp7olpQ___felix_m___3D_plastilina_robot_assembly_tool_on_clean_white_back_977dde90-fbc5-42d4-a507-c1ae58091966.png?width=300&height=300&aspect_ratio=100:101",
          description: "Deploy this bot on social or on your website.",
        },

        // More top perks here...
      ],
      recog: [
        /*
        {
          title: "Better AI tools",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/B4JyFgFjyD/WBSYmikql2___felix_m___3D_plastilina_robot_brain_assembly_tools_on_clean_whi_064f5996-81b7-43c5-b857-8a8112b22616.png",
          description: "Smarter AI engine, more features, more fun.",
        },
        {
          title: "More integrations",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/1Fjth006Wa/CqRlp7olpQ___felix_m___3D_plastilina_robot_assembly_tool_on_clean_white_back_977dde90-fbc5-42d4-a507-c1ae58091966.png?width=300&height=300&aspect_ratio=100:101",
          description: "Deploy this bot on social or on your website.",
        },
        {
          title: "More integrations",
          imgSrc:
            "https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/1Fjth006Wa/CqRlp7olpQ___felix_m___3D_plastilina_robot_assembly_tool_on_clean_white_back_977dde90-fbc5-42d4-a507-c1ae58091966.png?width=300&height=300&aspect_ratio=100:101",
          description: "Deploy this bot on social or on your website.",
        },*/
        {
          //   title: "More posts ",
          icon: "fal fa-users",
          description: "Get featured in the supporters list ",
        },
        {
          icon: "fal fa-star",
          description: "Display the bots you support on your profile",
        },
        {
          icon: "fal fa-badge",
          description: "Unlock supporter badges on your profile",
        },
        {
          //   title: "More posts ",
          icon: "fal fa-heart",
          description: "Show your support to AI creators",
        },

        // More top perks here...
      ],
      bot: null,
      loadingBot: true,
    };
  },
  mounted() {
    this.loadProfile();
  },
  computed: {
    boostCount() {
      return this.bot.boostCount || 0;
    },
  },

  methods: {
    loadProfile() {
      var id = this.$route.params.bot;
      window.API.botProfile(id)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          //  this.checkIfAllCallsAreCompleted();
          // this.loadReviews(id);
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {});
    },
  },
};
</script>

<style scoped>
.perk-item {
  margin-top: 12px;
}
.center {
  text-align: center;
}
img.cover {
  object-fit: cover;
}
img.contain {
  object-fit: contain;
}
.muted {
  opacity: 0.5;
}
</style>
