<template>
  <div class="steps">
    <div v-for="(level, index) in levels" :key="index" class="step-item" :class="stepClass(index)">
      <div class="step-marker">
        <span class="icon" v-if="stepIcon(index)">
          <i class="fa" :class="stepIcon(index)"></i>
        </span>
      </div>
      <div class="step-details">
        <p class="step-title">
          <span class="icon" v-if="index < currentLevel && index">
            <i class="fa fa-check"></i>
          </span>
          {{ level.name }}
        </p>
        <p>{{ level.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/bulma-steps.css"; // Local import here
export default {
  data() {
    return {
      levels: [
        {
          name: "No Level",
          //    , label: "This is the first step of the process.",
          step: 0,
        },
        {
          name: "Level 1",
          //    label: "This is the second step. You get here once you have completed the first step.",
          step: 1,
        },
        {
          name: "Level 2",
          //  label: "This is the third step. One more step before the end.",
          step: 2,
        },
        {
          name: "Level 3",
          //    label: "Final step. You have completed all the previous steps and end the process.",
          step: 3,
        },
      ],
    };
  },
  computed: {
    currentLevel() {
      if (this.boostCount < 2) return 0;
      if (this.boostCount < 15) return 1;
      if (this.boostCount < 30) return 2;
      return 3;
    },
    progress() {
      switch (this.currentLevel) {
        case 0:
          return (this.boostCount / 2) * 100;
        case 1:
          return ((this.boostCount - 2) / 13) * 100;
        case 2:
          return ((this.boostCount - 15) / 15) * 100;
        default:
          return 100;
      }
    },
  },
  props: {
    boostCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    stepClass(index) {
      if (index < this.currentLevel) return "is-completed is-success";
      if (index === this.currentLevel) return "is-active";
      return "";
    },
    checkmarkIcon(index) {
      if (index < this.currentLevel) return "fa-check";
      // if (index === this.levels.length - 1) return "fa-flag";
      return "";
    },
    stepIcon(index) {
      switch (index) {
        case 0:
          return "";
        case 1:
          return "fa-diamond";
        case 2:
          return "fa-fire";
        default:
          return "fa-rocket";
      }

      if (index < this.currentLevel) return "fa-diamond";
      // if (index === this.levels.length - 1) return "fa-flag";
      return "";
    },
  },
};
</script>
<style scoped>
body .steps .step-title {
  font-size: 14px !important;
}
</style>
